import { Thread, User, Loader, ShowErrorAction } from 'src/store/types';

export interface CommentInput {
  threadId?: number;
  questionId?: number;
  assessmentId?: number;
  documentDraftId?: number;
  parentId?: number;
  taggedUsersIds: number[];
  tagAdmins: boolean;
  files?: string;
  adminOnly: boolean;
  comment: string;
  authorId: number;
  companyId: number;
  documentId: number;
  refKey?: string;
}

export interface Comment {
  id: number;
  createdAt: string;
  updatedAt: string;
  comment: string;
  authorId: number;
  parentId?: number;
  threadId: number;
  adminOnly: boolean;
  files?: string;
}

export interface CommentListingState {
  comments: CommentListingIndexedItem;
  loader: Loader;
}

export interface CommentListingIndexedItem {
  [key: string]: Comment;
}

export const COMMENT_SAVE_REQUEST = 'COMMENT_SAVE_REQUEST';
export const COMMENT_SAVE_SUCCESS = 'COMMENT_SAVE_SUCCESS';
export const COMMENT_SAVE_ERROR = 'COMMENT_SAVE_ERROR';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR';

export const COMMENT_SHOW_QUESTION = 'COMMENT_SHOW_QUESTION';
export const COMMENT_SHOW_TEXT = 'COMMENT_SHOW_TEXT';
export const COMMENT_SHOW_THREAD = 'COMMENT_SHOW_THREAD';

export interface CommentSaveRequestAction {
  type: typeof COMMENT_SAVE_REQUEST;
  comment: Partial<CommentInput>;
}

export interface CommentSaveSuccessAction {
  type: typeof COMMENT_SAVE_SUCCESS;
  comment: Partial<CommentFromResponse>;
}

export type CommentSaveErrorAction = ShowErrorAction<typeof COMMENT_SAVE_ERROR>;

export interface CommentDeleteRequestAction {
  type: typeof COMMENT_DELETE_REQUEST;
  commentId: number;
  assessmentId?: number;
  documentDraftId?: number;
}

export interface CommentDeleteSuccessAction {
  type: typeof COMMENT_DELETE_SUCCESS;
  commentId: number;
}

export type CommentDeleteErrorAction = ShowErrorAction<
  typeof COMMENT_DELETE_ERROR
>;

export interface CommentShowSelectedQuestionAction {
  type: typeof COMMENT_SHOW_QUESTION;
  id: string;
}

export interface CommentShowSelectedTextAction {
  type: typeof COMMENT_SHOW_TEXT;
  id: string;
}

export interface CommentShowSelectedCommentAction {
  type: typeof COMMENT_SHOW_THREAD;
  id: string;
}

export interface CommentFromResponse extends Comment {
  parentId?: number;
  children?: CommentFromResponse[];
  author?: User;
  parent?: CommentFromResponse;
  thread?: Thread;
}

export interface CommentFromResponseIndexed {
  [key: number]: CommentFromResponse[];
}

export type CommentActionTypes =
  | CommentSaveRequestAction
  | CommentSaveSuccessAction
  | CommentSaveErrorAction
  | CommentDeleteRequestAction
  | CommentDeleteSuccessAction
  | CommentDeleteErrorAction;
