import { combineReducers } from 'redux';
import {
  COMMENT_DELETE_ERROR,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  COMMENT_SAVE_ERROR,
  COMMENT_SAVE_REQUEST,
  COMMENT_SAVE_SUCCESS,
  CommentActionTypes,
  Loader,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

export const loaderReducer = (
  state: Loader = loaderInitialState,
  action: CommentActionTypes,
): Loader => {
  switch (action.type) {
    case COMMENT_SAVE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case COMMENT_SAVE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case COMMENT_SAVE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    case COMMENT_DELETE_REQUEST: {
      return { ...loaderInitialState, loading: true };
    }
    case COMMENT_DELETE_SUCCESS: {
      return { ...loaderInitialState, success: true, completed: true };
    }
    case COMMENT_DELETE_ERROR: {
      return {
        success: false,
        loading: false,
        completed: true,
        error: action.message.toString(),
      };
    }
    default:
      return state;
  }
};

export const commentReducer = combineReducers<{ loader: Loader }>({
  loader: loaderReducer,
});
