/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import moment from 'moment';
import {
  FormState,
  InterviewState,
  AnswerListingIndexedItem,
  Question,
  Choice,
  QuestionType,
  QuestionTypes,
  Answer,
  FileData,
  RiskComparatorType,
} from 'src/store/types';
import { getAnswerValueByQuestionType } from 'src/store/selectors';
import { Name } from 'src/features/risk-assessment/components/InterviewQuestions/NameField';
import { Address } from 'src/features/risk-assessment/components/InterviewQuestions/AddressField';
import {
  ContactValues,
  ContactInfoFields,
} from 'src/features/risk-assessment/components/InterviewQuestions/ContactField';
import {
  TableRowInterface,
  HIPAAInfo,
  HIPAAInfoRow,
} from './riskAssessmentTableTypes';

const presentAnswerValueForTable = (type: QuestionType, answerValue: any) => {
  switch (type.key) {
    case QuestionTypes.NAME:
      const name = answerValue as Name;

      return `${name.firstName} ${name.lastName}`;

    case QuestionTypes.ADDRESS:
      const address = answerValue as Address;

      const street = address.streetExtra
        ? `${address.street || ''} ${address.streetExtra || ''},`
        : `${address.street || ''},`;

      return `${street} ${address.city || ''}, ${address.state || ''} ${address.zip || ''}`;

    case QuestionTypes.CONTACT_INFO:
      const contactInfo = answerValue as ContactValues;

      const fullName = `${contactInfo[ContactInfoFields.firstName] || ''} ${
        contactInfo[ContactInfoFields.lastName] || ''
      }`;

      return `${fullName}, ${contactInfo[ContactInfoFields.title]}, ${contactInfo[ContactInfoFields.email]}, ${
        contactInfo[ContactInfoFields.phone]
      }`;

    case QuestionTypes.CHECKBOX:
      const values = answerValue as string[];

      return values.length > 1 ? values.join(', ') : values[0];

    case QuestionTypes.FILE:
      return answerValue as FileData[];

    case QuestionTypes.DATE:
      return (answerValue as moment.Moment).format('MM/DD/YYYY');

    default:
      return answerValue as string;
  }
};

const pushHipaaInfo = (
  hipaaInfo: HIPAAInfoRow,
  newInfo: HIPAAInfo,
): HIPAAInfoRow => {
  const { analysis, actionItem, risk, delegate } = hipaaInfo;
  return {
    ...hipaaInfo,
    analysis: newInfo.analysis ? [...analysis, newInfo.analysis] : analysis,
    actionItem: newInfo.actionItem
      ? [...actionItem, newInfo.actionItem]
      : actionItem,
    risk: newInfo.risk
      ? risk
        ? [...risk, newInfo.risk]
        : [newInfo.risk]
      : risk,
    delegate: newInfo.delegate ? [...delegate, newInfo.delegate] : delegate,
  };
};

const baseHipaaInfo = (question: Question): HIPAAInfoRow => ({
  legalRef: question.legalRef,
  legalRefCmmc: question.legalRefCmmc,
  legalRefNist: question.legalRefNist,
  risk: [] as string[],
  actionItem: [] as string[],
  analysis: [] as string[],
  delegate: [] as string[],
});

const assertRiskConditions = (
  question: Question,
  answerValue: string,
): HIPAAInfoRow => {
  return question.riskConditions
    ? question.riskConditions.reduce(
        (hipaaInfo: HIPAAInfoRow, riskCondition) => {
          switch (riskCondition.comparator) {
            case RiskComparatorType.IS_EMPTY:
              return !answerValue
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            case RiskComparatorType.GREATER_THAN:
              return riskCondition.condition &&
                answerValue > riskCondition.condition
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            case RiskComparatorType.LESS_THAN:
              return riskCondition.condition &&
                answerValue < riskCondition.condition
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            case RiskComparatorType.IS:
              return riskCondition.condition &&
                answerValue === riskCondition.condition
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            case RiskComparatorType.IS_NOT:
              return riskCondition.condition &&
                answerValue !== riskCondition.condition
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            case RiskComparatorType.IS_OVER_YEARS:
              return riskCondition.condition &&
                moment().diff(answerValue, 'years') >=
                  Number(riskCondition.condition)
                ? pushHipaaInfo(hipaaInfo, riskCondition)
                : hipaaInfo;
            default:
              return hipaaInfo;
          }
        },
        baseHipaaInfo(question),
      )
    : baseHipaaInfo(question);
};

const getEntryHipaaInformation = (
  type: QuestionType,
  question: Question,
  answerValue: any,
): HIPAAInfoRow => {
  switch (type.key) {
    case 'checkbox':
      const values = (answerValue as string[]) || [];
      const choices = question.choices?.filter((c) =>
        values?.includes(c.label),
      ) as Choice[];
      return choices.reduce(
        (hipaaInfo: HIPAAInfoRow, choice) => pushHipaaInfo(hipaaInfo, choice),
        baseHipaaInfo(question),
      );
    case 'radio':
    case 'select':
    case 'yes-no':
      if (answerValue) {
        const value = answerValue as string;
        const choice = question.choices?.find(
          (c) => c.label === value,
        ) as Choice;
        const { analysis, actionItem, risk, delegate } = choice || {};
        return {
          analysis: analysis ? [analysis] : [],
          actionItem: actionItem ? [actionItem] : [],
          risk: risk ? [risk] : [],
          delegate: delegate ? [delegate] : [],
          legalRef: question.legalRef,
          legalRefCmmc: question.legalRefCmmc,
          legalRefNist: question.legalRefNist,
        };
      }
      return {
        analysis: [],
        actionItem: [],
        risk: [],
        delegate: [],
        legalRef: question.legalRef,
        legalRefCmmc: question.legalRefCmmc,
        legalRefNist: question.legalRefNist,
      };
    default:
      return assertRiskConditions(question, answerValue);
  }
};

export const dataMapper = (
  form: FormState,
  interview?: InterviewState,
): TableRowInterface[] => {
  const answersByQuestionId: AnswerListingIndexedItem = interview
    ? (interview.answers || []).reduce(
        (answerMap: AnswerListingIndexedItem, answer: Answer) => {
          return {
            ...answerMap,
            [answer?.questionId]: answer,
          };
        },
        {},
      )
    : {};

  const questions = form.questions
    ? form.questions.filter((q) => {
        let includes = false;
        if (interview && interview.visibleQuestionsIds) {
          includes = interview.visibleQuestionsIds.includes(q.id as number);
        }
        return !q.type.decoration && includes;
      })
    : ([] as Question[]);

  return questions.map((question: Question) => {
    const questionId = question.id as number;
    const questionType = question.type;
    const answer = answersByQuestionId[questionId];
    const answerId = answer && answer.id;
    const answerValue =
      answer && getAnswerValueByQuestionType(questionType, answer);
    const processedAnswerValue = answerValue
      ? presentAnswerValueForTable(questionType, answerValue)
      : 'Not answered';
    const hipaaInfo = getEntryHipaaInformation(
      questionType,
      question,
      answerValue,
    );
    const actionItemCompletedAt = answer && answer.actionItemCompletedAt;

    return {
      questionId,
      question: question.question,
      typeKey: question.type.key,
      answerId,
      answer: processedAnswerValue,
      hipaaInfo,
      actionItemCompletedAt,
    };
  });
};
